import '../GameCardFlamingCore.css';
import logoSteam from '../img/logoSteamComingSoon.webp';
export const GameCardFlamingCore = () => {
    return (
        <div className='background-img-flamingCore mask-flamingcore'>
            <div className="container-gameCard">
                <div className=" box-content text-container">
                    <div>
                        <h2 className='flamingCore-section__title'>
                            Flaming Core Defence
                        </h2>
                        <h3 className='game-section__subtitle'>Arcade Series</h3>
                        <p className='p-flamingCore '>
                            A conscious AI has grown bored with conquering the universe and has dedicated itself to recreating human video games in its playgrounds. Join the CORE in a game to defend him against the enemies he has created for himself! Is he some kind of technological masochist? Who knows!
                        </p>
                    </div>
                </div>
                <div className="card">
                    <div className="background-flamingCore"></div>
                    <div className="box box1">
                        <img src={logoSteam} alt="Logo STEAM" className='logo-steam logo-svg steam ' />
                    </div>
                </div>
            </div>
        </div>
    )
}