import '../Home.css';
import logoFlamingTongue from '../img/logo-flaming-tongue.webp';

export const Home = () => {
  return (
    <div className='home-containers mask-home '>
      <div className='home-img'>
        <img src={logoFlamingTongue} alt="Flaming Tongue Studios Logo" className="home-logo" />
      </div>
    </div>
  )
};
