import '../Footer.css'
import logoFlamingTongue from "../img/logo-flaming-tongue.webp";
export const Footer = () => {
    return (
        <footer className="app-footer app-footer__content-container mask-footer" >
            <img src={logoFlamingTongue} alt="Logotipo de Flaming Tongue" className="app-footer__logo" />
            <div className="app-footer__links" >
                <h3 className="app-footer__link">
                    Contact
                </h3>
                <h3 className="app-footer__link">contact.flaming.tongue@gmail.com</h3>
            </div>
        </footer>
    )
}