import '../GameCardAdhd.css';
import logoSteam from '../img/logoSteam.webp';
export const GameCardAdhd = () => {
    return (
        <div className='background-img-adhd mask-adhd'>
            <div className="container-gameCard">
                <div className="box-content text-container">
                    <div
                    >
                        <h2 className='game-section__title'>
                            ADHD Arena
                        </h2>
                        <h3 className='game-section__subtitle'>Arcade Series</h3>
                        <p>
                            Let your ADHD loose and stack gems to accumulate points and get to higher more rewarding tiers, get the highest score you can by pushing gems around to organize and combine them, get lost in the joys of creating chaos and then putting it into order!
                        </p>
                    </div>
                </div>
                <div className="card">
                    <div className="background-adhd"></div>
                    <div className="box box1" onClick={() => window.open('https://store.steampowered.com/app/2623080/Adhd_Arena/', '_blank')}>
                        <img src={logoSteam} alt="Logo STEAM" className='logo-steam logo-svg steam ' />
                    </div>
                </div>
            </div>
        </div>
    );
};