import './App.css';
import { Footer } from './components/Footer';
import { GameCardAdhd } from './components/GameCardAdhd';
import { GameCardFlamingCore } from './components/GameCardFlamingCore';
import { Header } from './components/Header';
import { Home } from './components/Home';

function App() {
  return (
    <div>
      <Header />
      <main>
        <section className="home-container">
          <Home />
        </section>
        <section className='gameCardsContainer'>
          <GameCardAdhd></GameCardAdhd>
          <GameCardFlamingCore></GameCardFlamingCore>
        </section>
      </main>
      <Footer />
    </div>
  );
}


export default App;
