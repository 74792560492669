import React from 'react';
import '../Header.css';
import logoFlamingTongue from '../img/logo-flaming-tongue.webp';

export const Header = () => {
  return (
    <header className='app-header'>
      <div className='app-header__container'>
        <a href='\#'>
          <img src={logoFlamingTongue} alt="Flaming Tongue Studios Logo" className="app-header__logo" />
        </a>
      </div>
      <nav className="app-header__links">
        {/* <ul className="app-header__links-list">
          <li className="app-header__link-list-item" >
            <a href="/Quienes Somos" className="app-header__link" >Quienes Somos</a>
          </li><li className="app-header__link-list-item" >
            <a href="/ADHD Arena" className="app-header__link">ADHD Arena</a></li>
        </ul> */}
      </nav>
    </header>
  );
};
